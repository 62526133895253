@media screen and (max-width: 1080px) {
  .top-menu,
  .login-section,
  .language-selector,
  .left-menu-wrapper,
  footer {
    display: none;
  }

  .content {
    min-height: 100vh;
  }

  //запрет скрола при открытом меню
  body.lock {
    overflow: hidden;
  }

  .link {
    font-size: 18px;
  }

  .linkIcon {
    width: 20px;
    height: 20px;
    color: $lightBrownFilled;
    cursor: pointer;
    margin-right: 10px;
  }
  //классы для мобильной ссылки, которая не входит в блок ссылок
  .mobile-section__link {
    position: relative;
    display: block;
    margin-bottom: 20px;

    span {
      font-size: 22px;
      line-height: 26px;
      font-weight: 500;
      color: $lightBrownFilled;
      transition: all 0.3s ease 0s;
      cursor: pointer;
    }
  }

  .mobile-section__link.active-link a {
    color: $lightGreenFilled;
  }

  .mobile-section {
    display: block;
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $darkGreenFilled;
    padding: 80px 0 0 0;
    overflow: auto;
    transition: all 0.3s ease 0s;

    .mobile-section__wrapper {
      position: absolute;
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      padding-left: 21px;
      padding-top: 90px;
      background-color: $almostWhiteTransparent;
      border-radius: 20px;
      overflow: auto;

      .additionalInfo {
        position: absolute;
        bottom: 20px;
      }
    }
  }

  .mobile-section__links-block {
    position: relative;

    .mobile-section__title {
      margin-bottom: 20px;
      cursor: pointer;
      z-index: 1;

      span {
        font-size: 22px;
        font-weight: 500;
        color: $lightBrownFilled;
      }
    }

    .mobile-section__links {
      max-height: 0;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      padding-left: 50px;

      //классы для мобильной ссылки, входящей в группы ссылок
      .mobile-section__link {
        position: static;
        display: none;

        span {
          font-size: 18px;
        }
      }

      .mobile-section__link.active-link a {
        color: $lightGreenFilled;
      }
    }

    .mobile-section__links.active {
      max-height: 300px;
      opacity: 1;

      .mobile-section__link {
        display: block;
      }
    }

    .mobile-section__links.center {
      padding-left: 0;
      text-align: center;
    }
  }

  .mobile-section.active {
    top: 0;
  }

  .logo {
    padding-top: 20px;
    padding-left: 20px;
    z-index: 5;

    span {
      font-size: 36px;
    }
  }

  .logo.active {
    padding-top: 30px;
    padding-left: 25px;

    span {
      font-size: 30px;
    }
  }

  .content {
    padding-top: 80px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .about-milkins {
    width: 100%;
    margin-top: 30px;
  }

  .promo-section {
    width: 95%;
  }

  .menu-logo-burger-container__burger {
    display: block;
    align-items: end;
    margin-right: 25px;
    margin-top: 38px;
    position: relative;
    width: 30px;
    height: 20px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    z-index: 5;

    span {
      position: absolute;
      background-color: $darkGreenFilled;
      left: 0;
      width: 100%;
      height: 2px;
      top: 9px;
    }

    &:before,
    &:after {
      content: '';
      background-color: $darkGreenFilled;
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      transition: all 0.3s ease 0s;
    }

    &:before {
      top: 0;
    }

    &:after {
      bottom: 0;
    }
  }

  .menu-logo-burger-container__burger.active:before {
    transform: rotate(45deg);
    top: 9px;
    background-color: $darkGreenFilled;
  }

  .menu-logo-burger-container__burger.active:after {
    transform: rotate(-45deg);
    bottom: 9px;
    background-color: $darkGreenFilled;
  }

  .menu-logo-burger-container__burger.active span {
    transform: scale(0);
  }

  .top-menu ul.top-menu__links {
    margin-left: 20px;
    display: block;
  }

  .top-menu__item {
    margin: 0 0 20px 0;
  }

  .copyright-info {
    padding: 0 20px;

    div {
      text-align: center;

      a.copyright-info__link {
        font-size: 14px;
      }
    }

    span {
      font-size: 12px;
    }
  }

  .popup .popup__body .popup__content {
    padding: 30px 15px;
  }

  .popup .popup__body {
    padding: 10px 15px;
  }

  .success-message {
    line-height: 25px;
    width: 100%;
    white-space: pre-wrap;
    text-align: center;
    margin-bottom: 30px;

    span {
      color: $lightGreenFilled;
    }
  }

  .table-line:hover {
    width: 100%;
  }

  .content .content-flex-wrapper {
    display: block;
  }

  .table-line__cell {
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      font-size: 14px;
    }

    .pet-logo {
      span {
        font-size: 38px;
      }
    }
  }

  .table-line__cell.cell-75.pet-details-in-cell {
    width: 180px;

    span {
      font-size: 18px;
    }
  }

  .search-block {
    min-width: 230px;
  }
  .progress-bar {
    .progress {
      span {
        font-size: 14px;
        padding-left: 15px;
      }
    }

    .progress.green {
      min-width: 33%;
    }
  }
  .breed-info-block__wrapper {
    .breed-info__section {
      .breed-info__section-title {
        padding-left: 0;
      }
    }

    .breed-info-block__aggregated-mark {
      .aggregated-mark__mark-comment {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .breed-info-block__details {
    width: 100%;

    .breed-info-block__params {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .breed-info-block__params-interlayer {
        .breed-info-block__params-param {
          width: 100%;
          min-width: 300px;
          max-width: 100%;
          margin-left: 0;
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;


          .param-label {
            width: 100%;
            display: flex; /* Добавляем flexbox */
            justify-content: flex-start; /* Выровнять содержимое по началу */

            span {
              line-height: 20px;
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              color: $lightBrownFilled;
            }
          }

          .progress-bar {
            .progress {
              span {
                font-size: 12px;
                padding-right: 20px;
              }
            }
          }
        }
      }
    }
  }

  .read-more-container {
    //width: 100%;
    .read-more-text {
    }
  }


  .article-with-title {
    margin: 20px 0 0;
  }
  .search-block-with-actions {
    .search-block-with-actions__actions {
      margin-right: 0;
    }
  }

  .circle-photo__wrapper {
    margin-top: 15px
  }

  .information-block-wrapper {

    .information-block-content {
      padding: 13px;

      .information-block-content__header {
        font-size: 16px;
      }

      .information-block-content__body {
        .information-block-content__body-item {
          .item-title {
            max-width: 125px;
            min-width: 125px;

            span {
              width: 115px;
            }
          }

          .item-content {
            font-size: 14px;
            display: flex;
            align-items: center;
            min-height: 40px;

            span {
              display: inline-block;
              vertical-align: center;
              line-height: 22px;
            }
          }

          .item-action {
            .fa-angle-right {
            }
          }
        }
      }
    }
  }

  .section-title.breed {
    top: 20px;
  }

  .updated-element__wrapper {
    line-height: 40px;

    .updated-element__header {
      font-size: 20px;
      line-height: 26px;
      margin-left: 0;
      margin-bottom: 26px;

      .fa-arrow-left {
        width: 20px;
        height: 20px;
        margin-right: 10px;

        &:hover {
          color: $orangeFilled;
        }
      }
    }

    .updated-element__content {
      font-size: 14px;
      margin-left: 0;
    }
  }

  .light-brown-btn {
    width: 136px;
    line-height: 50px;
    border-radius: 10px;
    background-color: $lightBrownFilled;
    color: $almostWhiteFilled;
    cursor: pointer;
    outline: 2px solid $lightBrownFilled;
    text-align: center;
    position: relative;
    transition: all 0.3s ease 0s;

    span.light-brown-btn__label {
      font-size: 16px;
    }

    &:hover {
      background-color: $almostWhiteFilled;
      color: $lightBrownFilled;
    }
  }

  .login-btn-wrapper {
    margin-bottom: 30px;
  }

  .pet-name-with-age {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    cursor: default;

    span {
      line-height: 30px;
      font-size: 22px;
      font-weight: bold;
      color: $darkGreenFilled;

    }

    .pet-name-with-age__birthdate {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .section-title {
    width: fit-content;
    margin: 0 auto;
    text-align: center;

    .greetings {
      padding: 20px 0 30px 0;
      cursor: default;

      h1 {
        font-size: 24px;
        font-weight: 900;
        color: $darkGreenFilled;
        /*color: $almostWhiteFilled;
        background-color: $darkGreenFilled;*/
      }
    }
  }

  .section-title.breed {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    width: auto;
    background: $lightBrown03;

    letter-spacing: .03rem;

    .greetings {
      padding: 10px 0;
      line-height: 34px;

      h1 {
        font-size: 26px;
        background: none;
      }
    }

    .greetings._editable {
      h1 {
        display: inline;
        padding-right: 10px;
      }

      a {
        cursor: pointer;

        img {
          height: 38px;
          width: 38px;
        }
      }
    }
  }

  .breed-characteristics {
    position: absolute;
    right: 15px;
    top: 265px;

    .breed-characteristics__item {
      background: $lightBrown03;
      margin-bottom: 10px;
      max-width: 150px;

      .breed-characteristics__item-header {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3em;
        color: $almostWhiteFilled;
        margin-bottom: 2px;
      }

      .breed-characteristics__item-body {
        font-size: 14px;
        line-height: 1.3em;
        color: $almostWhiteFilled;
      }
    }
  }

  .gender-params__wrapper {
    right: 15px;
    top: 245px;
    width: 140px;

    .gender-params__params-section {
      .gender-params__param {
        background-color: $lightBrown03;
        display: flex;
        line-height: 36px;

        .gender-params__param-icon {
          width: 40%;
          max-height: 40px;
          display: flex;
          align-items: center; /* Центрирование по вертикали */
          justify-content: center; /* Центрирование по горизонтали (если нужно) */
          img {
            max-height: 26px;
            width: auto; /* Сохранение пропорций изображения */
            object-fit: contain; /* Вписывание картинки в контейнер */
          }
        }

        .gender-params__param-data {
          span {
            font-size: 12px;
            font-weight: 600;
          }
        }
      }

      .gender-params__param:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }

  .breed-info-block__wrapper {
    .breed-info-block__preview {
      .breed-primary-info {
        span {
          line-height: normal;
        }

        span.breed__first-mentioned {
          display: inline-block;
          word-wrap: break-word; /* Перенос длинных слов на новую строку */
          overflow-wrap: break-word; /* Альтернативное свойство переноса */
          color: $lightBrownFilled;
          font-size: 12vw;
          font-weight: 500;
        }

        span.breed__origin_country {
          font-size: 9vw;
        }
      }
    }
    .breed-info-block__header {
      span {
        text-align: center;
      }
    }
  }

  .information-block-content.no-borders {
    margin-top: 20px;
    padding: 0;

    .information-block-content__body {
      .information-block-content__body-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        align-items: flex-start;

        .item-title {
          max-width: 100%;
          min-width: 0;
          display: flex;
          padding-left: 0;

          span {
            width: 100%;
          }
        }
      }
    }
  }

  .dictionary-element-header__wrapper {
    margin-bottom: 30px;

    .dictionary-element-header {
      font-size: 32px;
      line-height: 50px;
      color: $lightBrownFilled;
      cursor: default;
      margin-left: 0;

      span {
        font-weight: 500;
      }

      .fa-arrow-left {
        width: 32px;
        height: 32px;
        color: $lightBrownTransparent;
        cursor: pointer;
        margin-right: 10px;

        &:hover {
          color: $lightBrownFilled;
        }
      }

      .fa-dictionary-edit {
        width: 24px;
        height: 24px;
        line-height: 50px;
        color: $lightBrownTransparent;
        cursor: pointer;
        margin-left: 15px;
        margin-bottom: 5px;

        &:hover {
          color: $lightBrownFilled;
        }
      }
    }
  }
  //  put your code before this line
}