//https://www.colorzilla.com/gradient-editor/ -- градиенты
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');
//links for icons
@import url('https://unicons.iconscout.com/release/v4.0.0/css/line.css');
@import "../common/variables.scss";
@import "../common/nullstyle.scss";
@import "form.scss";
@import "tabs.scss";
@import "custom_selector.scss";
@import "spinner.scss";


// блоки, которые будут видимы в мобильной версии
.menu-logo-burger-container__burger,
.burger-container,
.burger-navigation,
.mobile-section {
  display: none;
}

body {
  background-color: $darkGreenFilled;
}

a.backref__link {
  text-decoration: none;
  color: $lightBrownTransparent;

  &:hover {
    color: $lightBrownFilled;
  }
}

a.goto__link {
  text-decoration: none;
  color: $lightGreenFilled;
  padding-right: 10px;

  &:hover {
    color: $darkGreenFilled;
  }
}

div#root {
  .app-is-under-construction {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //overflow: auto;
    background-color: $almostWhiteFilled;

    .block {
      width: 280px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .logo {
        padding-top: 0;
        padding-left: 0;
        margin: auto;
      }

      .under-construction-label {
        padding-top: 20px;
        margin: auto;

        span {
          font-family: 'Roboto Slab';
          font-size: 20px;
          color: $darkGreenFilled;
          cursor: default;
        }
      }
    }
  }
}

body.lock {
  overflow: hidden;
}

.container {
  height: auto;
  background: url("/public/images/main-cat-q.jpg") no-repeat fixed center 0;

}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.link {
  font-size: 22px;
  font-family: 'Roboto';
  cursor: pointer;
  font-weight: 500;
  padding: 0 1em 0 0;
  color: $lightBrownTransparent;
  transition: all 0.3s ease 0s;

  &:hover {
    color: $lightBrownFilled;
  }
}

.link.small {
  font-size: 16px;
  font-weight: 400;
}

.link.small.forgot-password {
  color: $lightBrownFilled;

  &:hover {
    color: $darkGreenFilled;
  }
}

.link.active-link {
  color: $darkGreenFilled;
  cursor: default;
}

.pet_icon {
  width: 90px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px; /* Обеспечиваем небольшое пространство между элементами */
  transition: transform 0.5s ease; /* Сглаживание анимации перемещения */

  .circle {
    margin-top: 10px;
    color: $lightBrownFilled;
    height: 60px;
    width: 60px;
    cursor: pointer;
    transition: all 0.3s ease 0s;

    .circle__plus {
      position: absolute;
      height: 60px;
      width: 60px;
    }

    .circle__ellipsis {
      position: absolute;
      height: 60px;
      width: 60px;
    }

    .pet-logo {
      height: 80px;
      width: 80px;
      margin-top: -10px;
      font-size: 38px;
      font-weight: 600;
      line-height: 80px;
      border-radius: 50%;
      border: 2px solid $almostWhiteTransparent;
      background-color: $almostWhiteFilled;
      color: $lightBrownFilled;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease 0s;

      img {
        border-radius: 50%;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    &:hover {
      border-color: $darkGreenFilled;
      color: $darkGreenFilled;
    }
  }
}

.header-container {
  .logo {
    padding-top: 20px;
    padding-left: 25px;
    transition: all 0.3s ease 0s;

    span {
      font-family: 'Roboto Slab';
      font-size: 48px;
      color: $almostWhiteFilled;
      background-color: $orangeFilled;
      cursor: default;

      a {
        text-decoration: none;
        color: $almostWhiteFilled;
      }
    }
  }

  max-width: $maxContentWidthDesktop;
  margin: $fitToCenter;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Выровнять элементы по вертикали */
  width: 100%; /* Занимает всю ширину */

  .top-menu {
    display: flex;
    padding-top: 20px;
    align-items: center;

    .top-menu__left {
      flex: 2 1 100px;

      ul.top-menu__links {
        display: flex;

        li.top-menu__item {
          list-style: none;
          display: block;
          padding: 0 20px;

          a {
            text-transform: uppercase;
            font-size: 16px;
            position: relative;
            color: $lightBrownFilled;

            span {
              display: none;
            }

            &:hover {
              color: $darkGreenFilled;
              transition: all 0.3s ease 0s;
            }
          }

          a:hover span {
            top: 0;
            left: 40px;
            line-height: 32px;
            padding-left: 10px;
            padding-right: 10px;
            display: block;
            background: $almostWhite08;
            min-width: 50px;
            position: absolute;
            text-decoration: none;
            //z-index: 1;
          }
        }

        li.top-menu__item.active {
          color: $darkGreenFilled;
        }
      }
    }

    .top-menu__pets-block {
      width: 100px;
      //height: 100px;
      display: flex;
      position: relative;
    }


    .top-menu__pets-block {
      .top-menu__pets-additional_elements {
        display: flex;
        position: absolute;
        left: 100%; /* Позиционирование за пределами первого элемента */
        white-space: nowrap; /* Чтобы элементы располагались в одной строке */
        overflow: hidden; /* Скрываем дополнительные элементы до наведения */
        height: 80px;

        .pet_icon {
          width: 75px;
        }
      }
    }

    .top-menu__pets-block:hover .top-menu__pets-additional_elements .pet_icon {
      transform: translateX(0%); /* При наведении двигаем дополнительные элементы слева направо */
    }

    .top-menu__pets-additional_elements .pet_icon {
      transform: translateX(-250%); /* Исходное положение дополнительных элементов за пределами видимости */
      transition: transform 0.5s ease; /* Сглаживание анимации перемещения */
    }


    .top-menu__right {
      flex: 2 1 100px;
      line-height: 60px;
      min-width: 100px;
    }
  }
}


.updated-element__wrapper {
  margin-bottom: 30px;

  .updated-element__header {
    font-size: 24px;
    line-height: 50px;
    color: $darkGreenFilled;
    cursor: default;
    margin-left: 20px;

    span {
      font-weight: 500;
    }

    .fa-arrow-left {
      width: 24px;
      height: 24px;
      color: $darkGreenFilled;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: $orangeFilled;
      }
    }
  }

  .updated-element__content {
    white-space: break-spaces;
    margin-left: 20px;
    line-height: 20px;
    font-size: 16px;
  }
}


.left-menu-wrapper {
  flex: 0 1 80px;

  .left-menu {
    margin-right: 20px;
    background: $almostWhiteTransparent;
    border-radius: 10px;

    ul.left-menu__links {
      li.left-menu__item {
        list-style: none;
        padding: 7px;
        margin: 0 auto;
        text-align: center;

        a {
          text-transform: uppercase;
          font-size: 16px;
          position: relative;

          span {
            display: none;
          }

          &:hover {
            color: $darkGreenFilled;
            transition: all 0.3s ease 0s;
          }
        }

        a:hover span {
          top: 0;
          left: 40px;
          line-height: 32px;
          padding-left: 10px;
          padding-right: 10px;
          display: block;
          background: $almostWhite08;
          min-width: 50px;
          position: absolute;
          text-decoration: none;
          //z-index: 1;
        }
      }

      li.left-menu__item.active {
        color: $darkGreenFilled;
      }
    }

    a {
      color: $lightBrownFilled;
      font-weight: bold;


      &:hover {
        color: $lightGreenFilled;
      }
    }
  }
}


.login-section {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  padding-right: 25px;
  padding-top: 20px;
  flex-grow: 0;
  flex-shrink: 1;
}

.login-button-with-auth-user-menu {
  position: relative;
}

.orange-btn {
  width: 136px;
  min-height: 50px;
  border-radius: 10px;
  background-color: $orangeFilled;
  color: $almostWhiteFilled;
  cursor: pointer;
  outline: 2px solid $orangeFilled;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;
  display: flex;
  align-items: center;

  span.orange-btn__label {
    font-size: 16px;
    display: inline-block;
    line-height: 24px;
    margin: $fitToCenter;
  }

  &:hover {
    background-color: $almostWhiteFilled;
    color: $orangeFilled;
  }
}

.orange-btn.reverse {
  background-color: $almostWhiteFilled;
  color: $orangeFilled;

  &:hover {
    background-color: $orangeFilled;
    color: $almostWhiteFilled;
  }
}

.light-brown-btn {
  width: 136px;
  line-height: 50px;
  border-radius: 10px;
  background-color: $lightBrownFilled;
  color: $almostWhiteFilled;
  cursor: pointer;
  outline: 2px solid $lightBrownFilled;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;

  span.light-brown-btn__label {
    font-size: 16px;
  }

  &:hover {
    background-color: $almostWhiteFilled;
    color: $lightBrownFilled;
  }
}

.light-brown-btn.reverse {
  background-color: $almostWhiteFilled;
  color: $lightBrownFilled;

  &:hover {
    background-color: $lightBrownFilled;
    color: $almostWhiteFilled;
  }
}

.orange-btn.center {
  margin: 0 auto;
}

.orange-btn.center.disabled {
  margin: 0 auto;
  background-color: $lightBrownTransparent;
  color: $almostWhiteFilled;
  outline: 2px solid $lightBrownTransparent;
}

.remove-btn {
  width: 100px;
  background: url(#{$pathToIcons}/trash.png) no-repeat center;
  position: relative;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.light-green-btn {
  width: 136px;
  line-height: 50px;
  border-radius: 10px;
  background-color: $lightGreenFilled;
  color: $almostWhiteFilled;
  cursor: pointer;
  outline: 2px solid $lightGreenFilled;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;

  span.light-green-btn__label {
    font-size: 16px;
  }

  &:hover {
    background-color: $almostWhiteFilled;
    color: $lightGreenFilled;
  }
}

.dark-green-btn {
  width: 136px;
  line-height: 50px;
  border-radius: 10px;
  background-color: $darkGreenFilled;
  color: $almostWhiteFilled;
  cursor: pointer;
  outline: 2px solid $darkGreenFilled;
  text-align: center;
  position: relative;
  transition: all 0.3s ease 0s;

  span.dark-green-btn__label {
    font-size: 16px;
  }

  &:hover {
    background-color: $almostWhiteFilled;
    color: $darkGreenFilled;
  }
}

.dark-green-btn.center {
  margin: 0 auto 20px;
}

.orange-btn.promo {
  position: static;
  margin: 30px auto auto;
}

.language-container {
  margin: $fitToCenter;
  max-width: $maxContentWidthDesktop;
  display: flex;
  justify-content: flex-end;
  z-index: 2;
}

.content {
  max-width: $maxContentWidthDesktop;
  margin: $fitToCenter;
  padding: 120px 25px 50px;
  min-height: 90vh;
}

.content-flex-wrapper {
  display: flex;
}

.basic-information-block {
  flex: 2 1 970px; // flex-grow, flex-shrink и flex-basis
  padding: 15px;
  border-radius: 10px;
  background-color: $almostWhiteTransparent;
  max-width: 1400px;
  position: relative;
}

.panel-content {
  max-width: $maxContentWidthDesktop;
  margin: $fitToCenter;
  padding: 120px 25px 50px;
  min-height: 90vh;

  .basic-information-block {
    display: flex;
  }

  .admin-panel__menu {
    width: 220px;
    display: flex;

    .admin-panel__menu-items-wrapper {
      background: $almostWhiteFilled;
      transition: all 0.3s ease 0s;

      ul.admin-panel__menu-items {
        overflow-y: auto;

        li {
          border-radius: 10px;
          line-height: 50px;
          font-size: 16px;
          cursor: pointer;
          text-transform: capitalize;
          width: 220px;
          padding-left: 10px;
          transition: all 0.3s ease 0s;
          text-decoration: none;
          color: $lightBrownFilled;

          &:hover {
            background: $lightBrown01;
          }
        }

        li._selected {
          background: $lightBrownTransparent;
          color: $darkGreenFilled;
          font-weight: 400;
        }
      }
    }
  }

  .admin-panel__content {
    width: 100%;

    .admin-panel__content_header {
      margin-bottom: 30px;
      padding-left: 50px;
      text-align: center;

      h1 {
        font-size: 24px;
        font-weight: bold;
        color: $darkGreenFilled;
      }
    }

    .admin-panel__content_info {
      padding-left: 50px;
    }
  }
}

.backward-link-and-add-dict-icon__wrapper {
  display: flex;
  justify-content: space-between;

  .backward-link {
    display: flex;
  }

  .add-dict-icon {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
}


.backward-link-and-add-dict-icon__wrapper.hidden {
  display: none;
}

.dictionary-list {
  .dictionary-item {
    line-height: 30px;
    font-size: 16px;
    color: $lightBrownFilled;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.information-block-wrapper {
  width: 100%;
}

.information-block-content.no-borders:not(:last-child) {
  margin-bottom: 0;
}

.information-block-content.no-borders {
  border: none;
  margin-bottom: 0;

  .information-block-content__body {

    .information-block-content__body-item {
      min-height: 30px;
      cursor: default;
      border-bottom: none;
      border-radius: 10px;
      margin-bottom: 7px;

      .item-content {
        color: $lightBrownFilled;
        font-size: 16px;
      }

      .item-action {
        display: none;
      }

      &:hover {
        background-color: $lightBrown01;
      }
    }
  }
}

.dictionary-element-header__wrapper {
  margin-bottom: 10px;

  .dictionary-element-header {
    font-size: 32px;
    color: $lightBrownFilled;
    cursor: default;
    margin-left: 20px;

    .dictionary-title {
      margin-bottom: 5px;

      span {
        font-weight: normal;
        color: $lightBrownTransparent;
        font-size: 16px;
        text-transform: uppercase;

        a {
          color: $lightBrownTransparent;

          &:hover {
            color: $lightBrownFilled;
          }
        }
      }
    }

    span {
      font-weight: 500;
    }

    span.by-center {
      display: flex;
      justify-content: center; /* Горизонтальное выравнивание по центру */
      align-items: center; /* Вертикальное выравнивание по центру */
      width: 100%; /* Занимает всю ширину родителя */
    }

    .fa-arrow-left {
      width: 32px;
      height: 32px;
      color: $lightBrownTransparent;
      cursor: pointer;
      margin-right: 20px;

      &:hover {
        color: $lightBrownFilled;
      }
    }

    .fa-dictionary-edit {
      width: 24px;
      height: 24px;
      line-height: 50px;
      color: $lightBrownTransparent;
      cursor: pointer;
      margin-left: 15px;
      margin-bottom: 5px;

      &:hover {
        color: $lightBrownFilled;
      }
    }
  }
}

.information-block-content {
  border-radius: 20px;
  border: 1px solid $lightBrownTransparent;
  padding: 20px;

  .information-block-content__header {
    font-size: 18px;
    color: $darkGreenFilled;
    margin-bottom: 30px;
  }

  .information-block-content__body {

    .information-block-content__body-item {
      display: flex;
      width: 100%;
      transition: all 0.3s ease 0s;
      border-bottom: 1px solid $lightBrownTransparent;
      min-height: 40px;
      align-items: center;
      line-height: 22px;
      cursor: pointer;

      .item-title {
        display: flex;
        align-items: center;
        min-width: 210px;
        max-width: 210px;
        text-transform: uppercase;
        font-size: 12px;
        color: $lightBrownTransparent;
        flex-grow: 2;
        padding-left: 5px;

        span {
          width: 190px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .item-content {
        font-size: 16px;
        color: $darkGreenFilled;
        white-space: pre-line;
        flex-grow: 7;
      }

      .item-action {
        flex-grow: 1;
        text-align: end;
        padding-right: 10px;

        .fa-angle-right {
          color: $lightBrownTransparent;
        }
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }

    .information-block-content__body-item.disabled {
      .item-content {
        font-size: 16px;
        color: $lightBrownTransparent;
        flex-grow: 7;
      }

      .item-action {
        display: none;
      }

      &:hover {
        background-color: $almostWhiteFilled;
        cursor: default;
      }
    }

    .add-element__content {
      font-size: 14px;
      text-transform: uppercase;
      line-height: 40px;
      font-weight: 500;
      color: $lightBrownFilled;
      padding-left: 10px;
      transition: all 0.3s ease 0s;

      .add-element__content-plus {
        padding-right: 8px;
        cursor: pointer;
      }

      span {
        cursor: pointer;
      }

      &:hover {
        color: $darkGreenFilled;
      }
    }
  }

  .information-block-content__body > .add-element__content {
    margin-top: 20px;
  }

  .information-block-content__body > .add-element__content ~ .add-element__content {
    margin-top: 0;
  }
}

.information-block-content:not(:last-child) {
  margin-bottom: 30px;
}


.section-title {
  width: fit-content;
  margin: 0 auto;
  text-align: center;

  .greetings {
    padding: 20px 0 30px 0;
    cursor: default;

    h1 {
      font-size: 24px;
      font-weight: bold;
      color: $darkGreenFilled;
      /*color: $almostWhiteFilled;
      background-color: $darkGreenFilled;*/
    }
  }

  .greetings._editable {
    line-height: 38px;

    h1 {
      display: inline;
      padding-right: 10px;
    }

    a {
      cursor: pointer;

      img {
        height: 38px;
        width: 38px;
      }
    }
  }
}

.section-title.breed {
  position: absolute;
  top: 40px;
  left: 20px;
  width: auto;
  background: $lightBrown01;

  letter-spacing: .05rem;

  .greetings {
    padding: 10px 0;

    h1 {
      color: $almostWhiteFilled;
      font-size: 32px;
      font-weight: 500;
      background: none;
    }
  }
}

.section-title.breed.without-image {
  position: static;
  width: fit-content;
  margin: 40px auto;
  text-align: center;
  background: $lightBrown01;

  letter-spacing: .05rem;

  .greetings {
    padding: 10px 0;

    h1 {
      font-size: 32px;
      background: none;
      color: $darkGreenFilled;
    }
  }
}

.breed-photo__wrap-around {
  position: static;
  height: 400px;

  .breed-photo {
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: $almostWhiteTransparent;
    border-radius: 10px 10px 0 0;

    img {
      border-radius: 10px 10px 0 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}


.breed-photo__wrapper {
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .breed-photo__add-button {
    width: 100%;
    height: 100%;
    border: 1px dashed $lightBrownFilled;
    border-radius: 10px;
    justify-content: center;
    text-align: center;
    transition: all 0.3s ease 0s;
    color: $lightBrownTransparent;

    &:hover {
      border: 1px dashed $darkGreenFilled;
      color: $darkGreenFilled;
    }
  }

  .breed-photo__add-button span {
    line-height: 400px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.03em;
    transition: all 0.3s ease 0s;
  }

  .image-item {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .plain-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.fa-circle-info {
  padding-left: 15px;
  color: $lightBrown03;
  cursor: pointer;
  transition: all 0.3s ease 0s;

  &:hover {
    color: $lightBrownFilled;
  }
}

.circle-photo__wrapper {
  width: 100%;
  height: 300px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .circle-photo__add-button {
    width: 300px;
    height: 300px;
    line-height: 300px;
    border-radius: 50%;
    border: 1px solid $lightBrownTransparent;
    color: $lightBrownTransparent;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease 0s;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    .fa-camera {
      width: 150px;
      height: 150px;
      vertical-align: middle;
    }

    &:hover {
      color: $lightBrownFilled;
      border-color: $lightBrownFilled;
    }
  }

  .circle-photo__add-button.static {
    cursor: default;

    &:hover {
      color: $lightBrownTransparent;
      border-color: $lightBrownTransparent;
    }
  }

  .circle-image-item {
    position: relative;
    width: 300px;
    height: 300px;

    .fa-pencil {
      color: $lightBrownTransparent;
      position: absolute;
      right: 0;
      width: 25px;
      height: 25px;
      margin-top: 5px;
      cursor: pointer;
      transition: all 0.3s ease 0s;
      z-index: 5;

      &:hover {
        color: $lightBrownFilled;
      }
    }
  }

  .img-circle {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.gender-params__wrapper {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 160px;

  .gender-params__header {
    line-height: 34px;
    display: flex;
    cursor: pointer;

    .gender-params__header-icon {
      width: 50%;
      text-align: center;
      background-color: $lightBrown01;
      transition: all 0.3s ease 0s;

      &:hover {
        background-color: $lightBrown03;
      }
    }

    .gender-params__header-icon.active {
      background-color: $lightBrown03;
      cursor: default;
    }

    .gender-params__header-icon.male {
      border-radius: 10px 0 0 0;
      color: $maleColor;
    }

    .gender-params__header-icon.female {
      border-radius: 0 10px 0 0;
      color: $femaleColor;
    }
  }

  .gender-params__params-section {
    .gender-params__param {
      background-color: $lightBrown03;
      display: flex;
      line-height: 36px;

      .gender-params__param-icon {
        width: 40%;
        max-height: 40px;
        display: flex;
        align-items: center; /* Центрирование по вертикали */
        justify-content: center; /* Центрирование по горизонтали */
        img {
          max-height: 26px;
          width: auto; /* Сохранение пропорций изображения */
          object-fit: contain; /* Вписывание картинки в контейнер */
        }
      }

      .gender-params__param-data {
        width: 60%;
        display: flex;
        align-items: center; /* Центрирование по вертикали */
        justify-content: left; /* Центрирование по горизонтали (если нужно) */
        span {
          font-size: 14px;
          font-weight: 600;
          color: $almostWhite08
        }
      }
    }

    .gender-params__param:last-child {
      border-radius: 0 0 10px 10px;
    }
  }
}

.breed-characteristics {
  position: absolute;
  right: 20px;
  top: 20px;

  .breed-characteristics__item {
    background: $lightBrown01;
    margin-bottom: 20px;
    max-width: 150px;

    .breed-characteristics__item-header {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.3em;
      color: $almostWhiteFilled;
    }

    .breed-characteristics__item-body {
      font-size: 16px;
      line-height: 1.3em;
      color: $almostWhiteFilled;
    }
  }
}

.breed-facts {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 20px 10px;
  background-color: $lightBrown01;
  border-radius: 10px;

  .breed-facts__fact {
    max-width: 600px;
    margin: 20px auto;
    line-height: 1.33em;
    display: flex;

    p {
      font-size: 18px;
      font-weight: 400;
      display: inline-flex;
      vertical-align: bottom;
      align-items: center;


      &:before {
        content: '—';
        font-size: 24px;
        font-weight: bold;
        line-height: 1em;
        margin-right: 10px;
      }
    }
  }
}

.breed-info-block__wrapper {
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  justify-content: center; /* Центрирование по горизонтали */
  flex-direction: column;

  .breed-info-block__header {
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    margin-bottom: 15px;

    span {
      font-size: 32px;
      font-weight: 500;
      color: $lightBrownFilled;
      line-height: 40px;
    }
  }

  .breed-info-block__aggregated-mark {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center; /* Центрирование по вертикали */
    justify-content: center; /* Центрирование по горизонтали */
    flex-direction: column;
    margin-bottom: 15px;

    .aggregated-mark__mark-comment {
      margin-top: 15px;

      span {
        font-size: 14px;
        line-height: 1.33em;
        //letter-spacing: 0.02rem;
        word-break: break-word;
        text-transform: uppercase;
        color: $lightBrownFilled;
      }
    }
  }

  .breed-info__section {
    display: flex;
    flex-direction: column;


    .breed-info__section-title {
      justify-content: start; /* Центрирование по горизонтали */
      margin-bottom: -15px;
      padding-left: 23px;

      span {
        font-size: 22px;
        font-weight: 400;
        color: $lightBrownFilled;
        line-height: 2em;
      }
    }
  }
}

.breed-info__section-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

.breed-info__section-body.visible {
  max-height: 1000px;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin: 0 1rem;

  span {
    top: 0.7rem;
    position: absolute;
    width: .75rem;
    height: .1rem;
    background-color: $lightBrownFilled;
    display: inline-block;
    transition: all .2s ease;

    &:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }

    &:last-of-type {
      right: 0;
      transform: rotate(-45deg);
    }
  }

  &.active {
    span {
      transition: all .2s ease;
      &:first-of-type {
        transform: rotate(-45deg);
      }

      &:last-of-type {
        transform: rotate(45deg);
      }

    }
  }
}

.read-more-container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 15px;

  .read-more-text {
    color: $lightBrown03;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0;
    padding: 0;
    transition: all 0.3s ease 0s;

    &:hover {
      color: $lightBrownFilled;
    }
  }
}

.progress-bar.wide {
  height: 50px;
}

.breed-info-block__preview {
  width: 100%;
  max-width: 1200px;


  .breed-primary-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      line-height: 50px;
      color: $lightBrownFilled;
    }
  }
}

.breed-info-block__details {
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  max-height: 0;

  .details-label {
    margin-top: 30px;
    margin-bottom: -5px;

    span {
      padding-left: 15px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      color: $lightBrownFilled;
    }
  }

  .breed-info-block__params {
    max-width: 1000px;
    display: flex;
    justify-content: center;
    margin: 20px auto 20px auto;

    .breed-info-block__params-interlayer {
      display: flex;
      flex-wrap: wrap;

      .breed-info-block__params-param {
        width: 300px;
        margin-left: 50px; /* Расстояние между элементами */
        margin-bottom: 25px;


        .param-label {
          span {
            padding-left: 15px;
            line-height: 20px;
            font-size: 12px;
            font-weight: 400;
            text-transform: uppercase;
            color: $lightBrownFilled;
          }
        }

        .progress-bar {
          .progress {
            span {
              font-size: 12px;
              padding-right: 20px;
            }
          }
        }
      }

      .breed-info-block__params-param:nth-child(3n+1) {
        margin-left: 0; /* Обнуляем отступ у первого элемента в каждой строке */
      }
    }


  }

  .details-summary {
    text-align: justify;
    margin-bottom: 20px;

    span {
      line-height: 20px;
      color: $lightBrownFilled;
    }
  }
}

.breed-info-block__details.expanded {
  max-height: 1200px;
  font-size: 16px;
}

.progress-bar.thin {
  height: 35px;
  max-width: 350px;
}

.progress-bar {
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg,
          $lightBrown03 0%, /* Base color at 0% */
          $lightBrown01 100%); /* Back to Base at 100% */
  border-radius: 20px;
  display: flex;
  align-items: center;

  .progress {
    height: 105%;
    max-width: fit-content; /* Максимальная ширина по содержимому */
  ;
    border-radius: 25px; /* Закругленные концы только слева, чтобы не перекрывать общий закругленный правый конец */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    span {
      font-size: 16px;
      font-weight: 600;
      padding-left: 20px;
      text-transform: uppercase;
    }
  }

  .progress.green {
    min-width: 25%;
    background: linear-gradient(90deg,
            rgba(144, 238, 144, 0.8) 0%, /* Base color at 0% */
            rgba(80, 200, 120, 0.8) 25%,
            rgba(34, 139, 34, 0.8) 100%);

    span {
      color: rgba(34, 139, 34, 0.8);
    }
  }

  .progress.yellow {
    min-width: 50%;
    background: linear-gradient(90deg,
            $lightYellow08 0%, /* Light Yellow */
            $gold08 33%, /* Gold */
            $amber08 66%, /* Amber */
            $deepYellow08 100% /* Deep Yellow */
    );

    span {
      color: $deepYellow08;
    }
  }

  .progress.orange {
    min-width: 75%;
    background: linear-gradient(90deg,
            $lightOrange08 0%, /* Darker Light Orange */
            $tangerine08 33%, /* Darker Tangerine */
            $apricot08 66%, /* Darker Apricot */
            $deepOrange08 100% /* Darker Deep Orange */
    );

    span {
      color: $deepOrange08;
    }
  }

  .progress.red {
    min-width: 100%;
    background: linear-gradient(90deg,
            $lightRed08 0%, /* Darker Light Red (Tomato) */
            $firebrick08 33%, /* Firebrick */
            $darkRed08 66%, /* Dark Red */
            $burgundy08 100% /* Burgundy */
    );

    span {
      color: $burgundy08;
    }
  }
}


.article-with-title {
  margin: 20px 30px 50px;
  transition: all 0.3s ease 0s;

  .article-with-title__title span {
    font-size: 24px;
    color: $darkGreenFilled;
    font-weight: bold;
    line-height: 2em;
  }

  .article-with-title__title.source-header span {
    font-size: 16px;
  }

  .article-with-title__text {
    white-space: pre-line;

    p {
      font-size: 16px;
      line-height: 1.33em;
      letter-spacing: 0.02rem;
      word-break: break-word;
      text-align: justify;
    }
  }
}

.article-with-title.margin-top {
  margin-top: 400px;
}

.article-with-title__text.sources p {
  cursor: default;
  font-size: 12px;
}

.pads_wrapper {
  width: 100%;
}

.about-milkins {
  font-size: 22px;
  color: $darkGreenFilled;
  background-color: $almostWhiteTransparent;
  padding: 15px;
  border-radius: 20px;
  width: 440px;
  cursor: default;
}

.milkins-telegram-link__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .telegram-logo {
    height: 32px;
    width: 32px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
  }

  span {
    cursor: pointer;
    display: inline-block;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
    line-height: 40px;
    color: $lightBrownFilled;

    &:hover {
      color: $darkGreenFilled;
    }
  }
}


.content__user-group-login {
  width: 205px;
  padding: 15px 15px 45px;
  margin-top: 60px;
  background-color: $lightBrownTransparent;
  color: rgb(244, 240, 244);
  font-size: 20px;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
  letter-spacing: 0.05em;
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: $lightBrownFilled;
  }

  &:after {
    content: "";
    background: url(#{$pathToIcons}/arrow_next_icon__light.png) no-repeat 0 0;
    background-size: 35px;
    position: absolute;
    margin-left: 145px;
    margin-top: 5px;
    width: 35px;
    height: 35px;
    pointer-events: none;
    display: block;
  }
}

.section-with-links {
  margin-top: 60px;

  span.section-title {
    font-size: 28px;
    color: $almostWhiteFilled;
    font-weight: bold;
    background-color: $orangeFilled;
    cursor: default;
  }
}

.promo-section {
  padding: 25px;
  margin: 20px 0;
  background-color: $almostWhiteTransparent;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: fit-content;
  transition: all 0.3s ease 0s;

  .link-to-popup__text {
    font-size: 22px;
    font-weight: bold;
    color: $darkGreenFilled;
  }

  div.link-to-popup__arrow {
    background: url(#{$pathToIcons}/arrow_next_icon__dark.png) no-repeat 0 0;
    background-size: 35px;
    margin-left: 15px;
    margin-top: -5px;
    width: 35px;
    height: 35px;
    min-width: 35px;
    pointer-events: none;
    display: inline-block;
  }

  &:hover {
    background-color: $almostWhiteFilled;
  }
}

footer {
  max-width: $maxContentWidthDesktop;
  margin: $fitToCenter;
  /*position:absolute;
  bottom: 0;
  left:0;
  right: 0;*/
}

.footer-gradient {
  height: 150px;
  background-image: linear-gradient(to bottom, rgba(27, 44, 0, 0), $darkGreenFilled);
}

.copyright-info {
  justify-content: space-between;
  background-color: $darkGreenFilled;
  height: 100px;
  padding: 0 50px;
  display: flex;
  align-items: center;

  div {
    font-size: 16px;
    line-height: 1.15;
    letter-spacing: 0.02em;
    color: rgb(244, 240, 244);
    cursor: default;

    span {
      color: $almostWhiteTransparent;
    }

    a.copyright-info__link {
      cursor: pointer;
      color: $almostWhiteTransparent;

      &:hover {
        color: $almostWhiteFilled;
      }
    }
  }
}

div.close_btn {
  display: block;
  right: 15px;
  margin-top: -10px;
  position: absolute;
  width: 30px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
  z-index: 5;

  span {
    position: absolute;
    background-color: $darkGreenFilled;
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
  }

  &:before,
  &:after {
    content: '';
    background-color: $lightBrownTransparent;
    position: absolute;
    width: 100%;
    height: 2px;
    margin-top: 18px;
    transition: all 0.3s ease 0s;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &:hover:before,
  &:hover:after {
    background-color: $lightBrownFilled;
  }
}

.close_btn.close-image {
  right: 15px;
  margin-top: 5px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $transparentBlack;
  top: 0;
  left: 0;
  z-index: 1000;
  //такое скрытие блока нужно для дальнейшей анимации
  // анимировать display block/hidden нельзя
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.4s ease 0s;

  .popup__body {
    min-height: 100%;
    max-width: $maxContentWidthDesktop;
    margin: $fitToCenter;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    //transition: all 0.5s ease 0s;

    .popup__content {
      background-color: $almostWhiteFilled;
      color: $darkGreenFilled;
      max-width: 800px;
      padding: 30px;
      position: relative;
      border-radius: 20px;
      transition: all 0.5s ease 0s;

      .popup__title {
        font-size: 22px;
        margin: 0 0 1em 0;
      }

      .popup__text {
        padding-top: 15px;
        font-size: 16px;
      }
    }
  }

  .popup__content {
    transform: translate(0px, -100%);
    opacity: 0;
  }
}


.success-message {
  line-height: 30px;
  width: 100%;
  white-space: pre-wrap;
  text-align: center;

  span {
    color: $lightGreenFilled;
  }
}

.success-message.black {
  span {
    color: black;
  }
}

.close_btn:before {
  transform: rotate(45deg);
  top: 9px;
  background-color: $almostWhiteFilled;
}

.close_btn:after {
  transform: rotate(-45deg);
  bottom: 9px;
  background-color: $almostWhiteFilled;
}

.close_btn span {
  transform: scale(0);
}

.popup.open {
  opacity: 1;
  visibility: visible;
}

.popup.open .popup__content {
  transform: translate(0px, 0px);
  opacity: 1;
}

.simple-link {
  list-style: none;
  font-size: 16px;
  font-weight: 400;
  color: $lightBrownTransparent;
  transition: all 0.3s ease 0s;

  &:hover {
    color: $lightBrownFilled;
  }
}

.breadcrumb-wrapper {
  position: absolute;
  top: 20px;
  left: 20px;

  .breadcrumb {
    line-height: 22px;

    .breadcrumb-items {
      display: flex;

      .breadcrumb-item {
        list-style: none;
        font-size: 14px;
        margin-right: 13px;
        font-weight: 500;
        position: relative;

        &:before {
          content: '/';
          position: absolute;
          right: -10px;
        }
      }

      .breadcrumb-item:last-child:before {
        content: '';
      }

      .breadcrumb-item a {
        color: $lightBrownFilled;
        transition: all 0.3s ease 0s;

        &:hover {
          color: $darkGreenFilled;
        }
      }

      .breadcrumb-item.active {
        color: $lightGreenFilled;
        cursor: default;
      }
    }
  }
}

.table-line {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 50px;
  border-bottom: 1px solid $lightBrownFilled;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease 0s;

  &:hover {
    background-color: $lightBrown01;
  }

  .table-line__title {
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px;

    span {
      text-transform: uppercase;
      font-size: 12px;
      color: $lightBrownFilled;
    }
  }

  .table-line__value {
    width: 100%;
    padding: 10px 25px 10px 10px;
    overflow: hidden;
  }

  &:last-child {
    border-bottom: none;
  }
}

.table-line.with-arrow {
  &:after {
    content: '';
    background: url(#{$pathToIcons}/right_arrow.png) no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    right: 7px;
  }
}

.table-line._editable {
  &:hover {
    cursor: pointer;
  }
}

.admin-panel.total-count-of-elements {
  padding-left: 10px;
  cursor: default;

  span {
    font-weight: 600;
    color: $lightBrownFilled;
  }
}

.simple-table {
  margin-top: 20px;
  background-color: $lightBrown01;
  border-radius: 10px;

  .table-line.table-title {
    border-bottom: none;
  }

  .table-line__cell {
    text-align: center;
    color: $darkGreenFilled;
    height: 100%;

    span {
      display: inline-block;
    }
  }

  .cell-100 {
    width: 100%;
  }

  .cell-90 {
    width: 90%;
  }

  .cell-75 {
    width: 75%;
  }

  .cell-65 {
    width: 65%;
  }

  .cell-50 {
    width: 50%;
  }

  .cell-40 {
    width: 40%;
  }

  .cell-25 {
    width: 25%;
  }

  .cell-20 {
    width: 20%;
  }

  .cell-15 {
    width: 15%;
  }

  .cell-10 {
    width: 10%;
  }
}

.table-line__cell.small-text {
  font-size: 14px;
}

.table-line__cell.cell-25.pet-logo-in-cell {
  width: 130px;
  height: 100px;
  display: flex;
  align-items: center;

}

.table-line__cell.cell-75.pet-details-in-cell {
  width: 250px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.simple-table {
  .pet_icon {
    .circle {
      margin-top: 0;
    }
  }
}

.simple-table.no-result .table-line {
  cursor: default;
}

span.table-line__description {
  text-transform: uppercase;
  font-size: 12px;
  color: $lightBrownFilled;
}

.type-selector {
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .selector-label {
    font-family: "Roboto", sans-serif;
    color: $lightBrownFilled;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .selector-options {
    display: flex;

    .type-selector__item {
      border: 1px solid $lightBrownFilled;
      border-radius: 10px;
      color: $darkGreenFilled;
      width: 50%;
      height: 50px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease 0s;
      margin-right: 3px;

      span {
        line-height: 50px;
        font-weight: bold;
        font-size: 16px;
        text-transform: uppercase;
      }

      &:hover {
        color: $almostWhiteFilled;
        background-color: $darkGreenFilled;
        border: 1px solid $darkGreenFilled;
      }
    }

    .type-selector__item:last-child {
      margin-right: 0;
    }

    .type-selector__item.active {
      color: $almostWhiteFilled;
      background-color: $darkGreenFilled;
      border: 1px solid $darkGreenFilled;
    }

    .type-selector__item.disabled {
      color: $lightBrownTransparent;
      background-color: $almostWhiteFilled;
      border: 1px solid $lightBrownTransparent;
      cursor: default;
    }

    .type-selector__item.active.disabled {
      color: $almostWhiteFilled;
      background-color: $lightBrownTransparent;
      border: 1px solid $lightBrownTransparent;
      cursor: default;
    }
  }

}

.translation-lang-selector {
  margin: 0 auto 20px;
  max-width: 500px;
  display: flex;
  justify-content: center;

  .translation-lang-selector__item {
    border: 1px solid $lightBrownFilled;
    border-radius: 10px;
    color: $darkGreenFilled;
    width: 70px;
    height: 50px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    margin-right: 3px;


    span {
      line-height: 50px;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }

    &:hover {
      color: $almostWhiteFilled;
      background-color: $darkGreenFilled;
      border: 1px solid $darkGreenFilled;
    }
  }

  .translation-lang-selector__item:last-child {
    margin-right: 0;
  }

  .translation-lang-selector__item.active {
    color: $almostWhiteFilled;
    background-color: $darkGreenFilled;
    border: 1px solid $darkGreenFilled;
  }
}

.filter-parameters {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  .filter-parameters__item {
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;

    span {
      text-transform: uppercase;
      line-height: 30px;
      font-size: 14px;
    }
  }

  .filter-parameters__item.active {
    color: $lightGreenFilled;
  }
}

.search-block-with-actions {
  display: flex;
  justify-content: space-between;


  .search-block-with-actions__actions {
    display: flex;
    align-items: center;
    margin-right: 30px;
  }
}

.search-block {
  min-width: 300px;
  position: relative;
  display: flex;
  height: 3em;
  line-height: 3;
  overflow: hidden;
  border-radius: 10px;
  outline: 1px solid $lightBrownTransparent;
  transition: all 0.3s ease;

  &:after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: $lightBrownTransparent;
    color: $almostWhiteTransparent;
    cursor: pointer;
    pointer-events: none;
    transition: .25s all ease;
  }

  &:hover::after {
    color: $lightBrownFilled;
  }

  .search-block__select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    flex: 1;
    cursor: pointer;

    text-transform: uppercase;
    font-size: 14px;
    padding: 15px;
  }

  .search-block__select::-ms-expand {
    display: none;
  }
}

.title-by-center {
  text-align: center;
  margin-bottom: 20px;

  h2 {
    font-size: 22px;
    font-family: 'Roboto';
    font-weight: 500;
    color: $darkGreenFilled;
  }

  h3 {
    font-size: 18px;
    font-family: 'Roboto';
    font-weight: 500;
    color: $darkGreenFilled;
  }
}

.success-registration {
  position: relative;
  text-align: center;

  span.success-registration__content {
    padding-top: 20px;
    font-size: 16px;
    color: $darkGreenFilled;
    line-height: 1.15em;
    text-align: center;
  }

  .orange-btn {
    margin: 30px auto 0;
  }
}

.button-group {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.button-group > div:first-child {
  margin-right: 20px;
}

.success-registration__done {
  margin: 0 auto;
  width: 256px;
  height: 256px;
  background: url(#{$pathToIcons}/done.png) no-repeat fixed center 0;
}

.pet-content__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.pet-content__wrapper.mobile {
  display: block;
}

.plain-form {
  flex: 2 1 610px;
  position: relative;
  max-width: 1200px;
}

.add-pet-step-info__wrapper {
  cursor: default;
  padding-left: 40px;
  flex: 0 1 330px;
}

.add-pet-step-info__wrapper.mobile {
  cursor: default;
  margin-bottom: 40px;
  padding-left: 0;
}

.add-pet-step-info {
  line-height: 40px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease 0s;

  .dot {
    height: 10px;
    width: 10px;
    background-color: $lightBrownTransparent;
    margin-right: 15px;
    border-radius: 50%;
    display: inline-block;
  }

  .dot.active {
    background-color: $darkGreenFilled;
  }

}

.add-pet-step-info__mobile {
  line-height: 40px;
  max-width: 250px;
  margin: $fitToCenter;
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease 0s;

  .dot {
    height: 10px;
    width: 10px;
    background-color: $lightBrownTransparent;
    margin-right: 15px;
    border-radius: 50%;
    display: inline-block;

  }

  .dot.active {
    background-color: $darkGreenFilled;
    transform: scale(130%);
  }

  .dot:last-child {
    margin-right: 0;
  }
}

.pet-step-link {
  list-style: none;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $lightBrownTransparent;
}

.pet-step-link.mobile {
  max-width: 400px;
  margin: $fitToCenter;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  span {
    letter-spacing: 0.12em;
  }
}

.pet-step-link.active-step {
  color: $darkGreenFilled;
}


@import "../mobile/mobile.scss";