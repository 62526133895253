@import "../common/variables.scss";

.tabs-wrapper {
  max-width: 500px;
  display: flex;
  justify-content: center;
  margin: 0 auto 30px;

  .tabs-item {
    border-color: $darkGreenFilled;
    border-radius: 10px 10px 0 0;
    border-style: solid;
    border-bottom: none;
    border-width: 1px;
    cursor: pointer;
    color: $darkGreenFilled;
    height: 50px;
    width: 250px;
    margin-right: 3px;
    text-align: center;

    span {
      line-height: 50px;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
    }

    &:hover {
      color: $almostWhiteFilled;
      background-color: $darkGreenFilled;
      border: 1px solid $darkGreenFilled;
    }
  }

  .tabs-item.active {
    color: $almostWhiteFilled;
    background-color: $darkGreenFilled;
  }

  .tabs-item.disabled {
    border-color: $lightBrownTransparent;
    color: $lightBrownTransparent;
    background-color: transparent;
    pointer-events: none; // чтобы убрать событие при наведении
  }
}

@import "../mobile/mobile-tabs.scss";







