@media screen and (max-width: 1080px) {
  .tabs-wrapper {
    max-width: 400px;

    .tabs-item {
      max-width: 250px;

      span {
        font-size: 14px;
      }
    }
  }
}