//colors
$darkGreenFilled: rgba(27, 44, 0, 1); //#1b2c00
$transparentBlack: rgba(0, 0, 0, 0.7);

$lightGreenFilled: rgba(77, 119, 1, 1);
$lightGreenTransparent: rgba(77, 119, 1, 0.6);

$lightBrownFilled: rgba(128, 115, 117, 1);
$lightBrownTransparent: rgba(128, 115, 117, 0.5);
$lightBrown01: rgba(128, 115, 117, 0.1);
$lightBrown03: rgba(128, 115, 117, 0.3);

$almostWhiteFilled: rgba(255, 255, 255, 1);
$almostWhiteTransparent: rgba(255, 255, 255, 0.95);
$almostWhite03: rgba(255, 255, 255, 0.3);
$almostWhite06: rgba(255, 255, 255, 0.6);
$almostWhite07: rgba(255, 255, 255, 0.7);
$almostWhite08: rgb(255, 255, 255, 0.8);

$maleColor: rgba(0, 123, 255, 0.8);
$femaleColor: rgba(255, 105, 180, 0.8);

$orangeFilled: rgba(244, 101, 0, 1);
$orangeTransparent: rgba(244, 101, 0, 0.6);

/**
 * Цвета градиентов
 */
$lightYellow08: rgba(255, 255, 224, 0.8);
$gold08: rgba(255, 223, 0, 0.8);
$amber08: rgba(255, 191, 0, 0.8);
$deepYellow08: rgba(255, 165, 0, 0.8);
$deepYellow: rgba(255, 165, 0, 1);

$lightOrange08: rgba(255, 204, 153, 0.8);
$tangerine08: rgba(255, 128, 0, 0.8);
$apricot08: rgba(255, 140, 0, 0.8);
$deepOrange08: rgba(204, 85, 0, 0.8);
$deepOrange: rgba(204, 85, 0, 1);

$lightRed08: rgba(255, 99, 71, 0.8);
$firebrick08: rgba(204, 0, 0, 0.8);
$darkRed08: rgba(139, 0, 0, 0.8);
$burgundy08: rgba(128, 0, 32, 0.8);
$burgundy: rgba(128, 0, 32, 1);


$fitToCenter: 0 auto; // разместить блок по середине
$maxContentWidthDesktop: 1400px;

$pathToIcons: "/public/images/icons";
$pathToFlags: "/public/images/flags";
