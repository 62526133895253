@import "../common/variables.scss";

//language selector
.language-selector {
  width: 170px;
  border-radius: 10px;
  color: $darkGreenFilled;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease 0s;

  .language-selector__select-btn {
    height: 50px;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    justify-content: flex-end;
    display: flex;

    i {
      font-size: 31px;
      transition: transform 0.3s linear;
    }

    i._hide {
      display: none;
    }

    span.language-selector__selected-language {
      height: 30px;
      line-height: 30px;
      display: block;
    }

    .language-selector__selected-language.english::before {
      content: '';
      background: url(#{$pathToFlags}/england.png) no-repeat 0 0;
      width: 30px;
      height: 30px;
      background-size: 30px;
      margin-right: 7px;
      display: block;
      float: left;
    }

    .language-selector__selected-language.russian::before {
      content: '';
      background: url(#{$pathToFlags}/russia.png) no-repeat 0 0;
      width: 30px;
      height: 30px;
      background-size: 30px;
      margin-right: 7px;
      display: block;
      float: left;
    }
  }

  .language-selector__select-btn, ul.options li {
    display: flex;
    cursor: pointer;
    align-items: center;
    text-transform: capitalize;
  }
}

.selector__content {
  display: none;
  margin-top: 3px;
  padding: 0px 0px 10px 10px;
  border-radius: 10px;
  background: $almostWhiteFilled;
  position: absolute;
  z-index: 10;
  left: 37px;
  transition: all 0.3s ease 0s;
  box-shadow: 2px 3px 3px $lightBrownFilled;


  ul.options {
    margin-top: 10px;
    max-height: 190px;
    overflow-y: auto;
    padding-right: 10px;

    li {
      line-height: 50px;
      border-radius: 5px;
      padding: 0 20px;
      font-size: 16px;
      cursor: pointer;
      text-transform: capitalize;
      color: $darkGreenFilled;

      &:hover {
        background: $lightBrownTransparent;
      }
    }

    li._selected {
      background: $lightBrown01;
    }

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
      background: $lightBrownTransparent;
      border-radius: 25px;
    }
  }
}

.language-selector.mobile {
  display: block;
  position: static;
  width: 300px;
}

.language-selector.mobile .language-selector__select-btn {
  display: none;
}

.language-selector.mobile .selector__content {
  display: block;
  position: static;
}

.language-selector.active .selector__content {
  display: block;
}

.selector__content.active {
  display: block;
}

