@media screen and (max-width: 1080px) {
  .form.login-form {
    min-width: 100%;
    max-height: 600px;
  }

  .form-item {

    .form-item__label {
      width: min-content;
      max-width: 235px;
      line-height: 22px;
    }
    .form__input {
      text-align: inherit;
      padding-left: 13px;
      min-width: 100%; //316px
    }

    .form__input.forgot-password {
      min-width: 316px;
    }
  }

  .form-item.range {
    .form__input {
      min-width: min-content;
    }

    .form__input.forgot-password {
      min-width: 316px;
    }
  }

  .input-with-options-wrapper {
    position: relative;
    margin: 0 auto 30px;
    max-width: 1200px;

    .form__input {
      padding-left: 50px;
    }

    .input-with-options__trash-wrapper {
      position: absolute;
      left: 15px;
      top: 13px;
      color: $lightBrownTransparent;
      transition: all 0.3s ease 0s;
      cursor: pointer;

      &:hover {
        color: $lightBrownFilled;
      }
    }
  }



}
